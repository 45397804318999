import { Record } from "../../fable_modules/fable-library-js.4.21.0/Types.js";
import { record_type, string_type, enum_type, int32_type, bool_type, class_type } from "../../fable_modules/fable-library-js.4.21.0/Reflection.js";
import { minValue } from "../../fable_modules/fable-library-js.4.21.0/Date.js";

export function inboxMessageTypeToString(x) {
    switch (x) {
        case 0:
            return "Plume";
        case 1:
            return "Drifters";
        case 2:
            return "Note";
        default:
            return "Running";
    }
}

export function inboxMessageTypeFromString(x) {
    switch (x) {
        case "Plume":
            return 0;
        case "Drifters":
            return 1;
        case "Note":
            return 2;
        case "Progress":
            return 3;
        case "Job":
            return 4;
        default:
            return 2;
    }
}

export class InboxItem extends Record {
    constructor(id, unread, type$0027, created, content) {
        super();
        this.id = id;
        this.unread = unread;
        this["type\'"] = (type$0027 | 0);
        this.created = created;
        this.content = content;
    }
}

export function InboxItem_$reflection() {
    return record_type("Petimeter.Inbox.InboxItem", [], InboxItem, () => [["id", class_type("System.Guid")], ["unread", bool_type], ["type\'", enum_type("Petimeter.Inbox.MessageType", int32_type, [["Plume", 0], ["Drifters", 1], ["Note", 2], ["Progress", 3], ["Job", 4]])], ["created", class_type("System.DateTime")], ["content", string_type]]);
}

export function InboxItem_get_empty() {
    return new InboxItem("00000000-0000-0000-0000-000000000000", true, 2, minValue(), "");
}

export class InboxStatus extends Record {
    constructor(userId, unread) {
        super();
        this.userId = userId;
        this.unread = (unread | 0);
    }
}

export function InboxStatus_$reflection() {
    return record_type("Petimeter.Inbox.InboxStatus", [], InboxStatus, () => [["userId", string_type], ["unread", int32_type]]);
}

