import { Record, Union } from "../../fable_modules/fable-library-js.4.21.0/Types.js";
import { float64_type, tuple_type, array_type, float32_type, record_type, int32_type, union_type } from "../../fable_modules/fable-library-js.4.21.0/Reflection.js";
import { fold, append, item } from "../../fable_modules/fable-library-js.4.21.0/Array.js";

export class OceanProp extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Temperature", "Salinity", "Speed", "W", "UV", "UVW"];
    }
}

export function OceanProp_$reflection() {
    return union_type("Sorcerer.Types.OceanProp", [], OceanProp, () => [[], [], [], [], [], []]);
}

export class Period extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Year", "Month"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? item(x.fields[0], Period_get_Months()) : "Year";
    }
}

export function Period_$reflection() {
    return union_type("Sorcerer.Types.Period", [], Period, () => [[], [["Item", int32_type]]]);
}

export function Period_get_Months() {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
}

export function Period_get_Periods() {
    return append(["Year"], Period_get_Months());
}

export function Period__ToInt(x) {
    if (x.tag === 1) {
        return (x.fields[0] + 1) | 0;
    }
    else {
        return 0;
    }
}

export class StatProp extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Temperature", "Salinity", "Speed", "U", "V", "WaterTransport", "Current", "Undefined"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "salinity" : ((x.tag === 2) ? "sp" : ((x.tag === 3) ? "u" : ((x.tag === 4) ? "v" : ((x.tag === 5) ? "waterTransport" : ((x.tag === 6) ? "current" : ((x.tag === 7) ? "" : "temp"))))));
    }
}

export function StatProp_$reflection() {
    return union_type("Sorcerer.Types.StatProp", [], StatProp, () => [[], [], [], [], [], [], [], []]);
}

export function StatProp_FromString_Z721C83C5(s) {
    switch (s) {
        case "temp":
            return new StatProp(0, []);
        case "salinity":
            return new StatProp(1, []);
        case "sp":
            return new StatProp(2, []);
        case "u":
            return new StatProp(3, []);
        case "v":
            return new StatProp(4, []);
        case "waterTransport":
            return new StatProp(5, []);
        case "current":
            return new StatProp(6, []);
        default:
            return new StatProp(7, []);
    }
}

export class StatMetric extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Mean", "Std", "Var", "Q05", "Q25", "Q50", "Q75", "Q95", "Q99"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "Std" : ((x.tag === 2) ? "Var" : ((x.tag === 3) ? "Q05" : ((x.tag === 4) ? "Q25" : ((x.tag === 5) ? "Q50" : ((x.tag === 6) ? "Q75" : ((x.tag === 7) ? "Q95" : ((x.tag === 8) ? "Q99" : "Mean")))))));
    }
}

export function StatMetric_$reflection() {
    return union_type("Sorcerer.Types.StatMetric", [], StatMetric, () => [[], [], [], [], [], [], [], [], []]);
}

export function StatMetric_FromString_Z721C83C5(s) {
    switch (s) {
        case "Std":
            return new StatMetric(1, []);
        case "Var":
            return new StatMetric(2, []);
        case "Q05":
            return new StatMetric(3, []);
        case "Q25":
            return new StatMetric(4, []);
        case "Q50":
            return new StatMetric(5, []);
        case "Q75":
            return new StatMetric(6, []);
        case "Q95":
            return new StatMetric(7, []);
        case "Q99":
            return new StatMetric(8, []);
        default:
            return new StatMetric(0, []);
    }
}

export class V2$1 extends Record {
    constructor(X, Y) {
        super();
        this.X = X;
        this.Y = Y;
    }
}

export function V2$1_$reflection(gen0) {
    return record_type("Sorcerer.Types.V2`1", [gen0], V2$1, () => [["X", gen0], ["Y", gen0]]);
}

export class PlainGrid extends Record {
    constructor(Vertices, Indices) {
        super();
        this.Vertices = Vertices;
        this.Indices = Indices;
    }
}

export function PlainGrid_$reflection() {
    return record_type("Sorcerer.Types.PlainGrid", [], PlainGrid, () => [["Vertices", array_type(float32_type)], ["Indices", array_type(int32_type)]]);
}

export function PlainGrid_get_empty() {
    return new PlainGrid(new Float32Array(0), new Int32Array(0));
}

export class BBox extends Record {
    constructor(minX, maxX, minY, maxY, center) {
        super();
        this.minX = minX;
        this.maxX = maxX;
        this.minY = minY;
        this.maxY = maxY;
        this.center = center;
    }
}

export function BBox_$reflection() {
    return record_type("Sorcerer.Types.BBox", [], BBox, () => [["minX", float32_type], ["maxX", float32_type], ["minY", float32_type], ["maxY", float32_type], ["center", tuple_type(float32_type, float32_type)]]);
}

export function BBox_get_empty() {
    return new BBox(3.4028234663852886E+38, -3.4028234663852886E+38, 3.4028234663852886E+38, -3.4028234663852886E+38, [0, 0]);
}

export class BoundingPolygon extends Record {
    constructor(count, coordinates) {
        super();
        this.count = (count | 0);
        this.coordinates = coordinates;
    }
}

export function BoundingPolygon_$reflection() {
    return record_type("Sorcerer.Types.BoundingPolygon", [], BoundingPolygon, () => [["count", int32_type], ["coordinates", array_type(tuple_type(float32_type, float32_type))]]);
}

export function BoundingPolygon_get_empty() {
    return new BoundingPolygon(0, []);
}

export class ArchiveArea extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Box", "Polygon"];
    }
}

export function ArchiveArea_$reflection() {
    return union_type("Sorcerer.Types.ArchiveArea", [], ArchiveArea, () => [[["Item", BBox_$reflection()]], [["Item", BoundingPolygon_$reflection()]]]);
}

export function ArchiveArea__center(x) {
    if (x.tag === 1) {
        const p = x.fields[0];
        const tupledArg_2 = fold((tupledArg, tupledArg_1) => [tupledArg[0] + tupledArg_1[0], tupledArg[1] + tupledArg_1[1]], [0, 0], p.coordinates);
        const n = p.coordinates.length;
        return [tupledArg_2[0] / n, tupledArg_2[1] / n];
    }
    else {
        return x.fields[0].center;
    }
}

export class Arrow$1 extends Record {
    constructor(MetersPerSecond, Points) {
        super();
        this.MetersPerSecond = MetersPerSecond;
        this.Points = Points;
    }
}

export function Arrow$1_$reflection(gen0) {
    return record_type("Sorcerer.Types.Arrow`1", [gen0], Arrow$1, () => [["MetersPerSecond", float64_type], ["Points", array_type(V2$1_$reflection(gen0))]]);
}

