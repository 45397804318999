import { Union, Record } from "../../fable_modules/fable-library-js.4.21.0/Types.js";
import { union_type, string_type, unit_type, list_type, anonRecord_type, float64_type, tuple_type, array_type, float32_type, record_type, bool_type, lambda_type, option_type, int32_type, class_type } from "../../fable_modules/fable-library-js.4.21.0/Reflection.js";
import { StatMetric_$reflection, Period_$reflection, StatProp_$reflection, Arrow$1_$reflection, V2$1_$reflection, PlainGrid_$reflection } from "./Types.jsx";
import { DriftersVariant_$reflection } from "../Archmaester/Types.jsx";
import { ParticleType_$reflection, GroupType_$reflection, LayerType_$reflection, SedimentKind_$reflection, ParticleState_$reflection, ParticleKind_$reflection, GroupKind_$reflection, FieldKind_$reflection, ApiParticle_$reflection } from "../Drifters/ApiTypes.jsx";

function routeBuilder(system, typeName, methodName) {
    return `/api/v2/${system}/${typeName}/${methodName}`;
}

export const Fvcom_routeBuilder = (typeName) => ((methodName) => routeBuilder("fvcom", typeName, methodName));

export class Fvcom_Archive extends Record {
    constructor(GetTime, GetNLayers, GetPlainGrid, GetNumFrames, GetTimeStep, EquipArchiveCache) {
        super();
        this.GetTime = GetTime;
        this.GetNLayers = GetNLayers;
        this.GetPlainGrid = GetPlainGrid;
        this.GetNumFrames = GetNumFrames;
        this.GetTimeStep = GetTimeStep;
        this.EquipArchiveCache = EquipArchiveCache;
    }
}

export function Fvcom_Archive_$reflection() {
    return record_type("Sorcerer.Api.Fvcom.Archive", [], Fvcom_Archive, () => [["GetTime", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(class_type("System.DateTime"))])))], ["GetNLayers", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["GetPlainGrid", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [PlainGrid_$reflection()]))], ["GetNumFrames", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["GetTimeStep", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["EquipArchiveCache", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [bool_type]))]]);
}

export class Fvcom_Layer extends Record {
    constructor(GetBathymetry, GetSalinity, GetTemperature, GetZeta, GetSpeed, GetUv) {
        super();
        this.GetBathymetry = GetBathymetry;
        this.GetSalinity = GetSalinity;
        this.GetTemperature = GetTemperature;
        this.GetZeta = GetZeta;
        this.GetSpeed = GetSpeed;
        this.GetUv = GetUv;
    }
}

export function Fvcom_Layer_$reflection() {
    return record_type("Sorcerer.Api.Fvcom.Layer", [], Fvcom_Layer, () => [["GetBathymetry", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))], ["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))], ["GetTemperature", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))], ["GetZeta", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))], ["GetUv", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))]))))]]);
}

export class Fvcom_Node extends Record {
    constructor(GetSiglev, GetSiglay, GetArt1, GetBathymetry, GetTemp, GetSalinity) {
        super();
        this.GetSiglev = GetSiglev;
        this.GetSiglay = GetSiglay;
        this.GetArt1 = GetArt1;
        this.GetBathymetry = GetBathymetry;
        this.GetTemp = GetTemp;
        this.GetSalinity = GetSalinity;
    }
}

export function Fvcom_Node_$reflection() {
    return record_type("Sorcerer.Api.Fvcom.Node", [], Fvcom_Node, () => [["GetSiglev", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetSiglay", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetArt1", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [float32_type])))], ["GetBathymetry", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [float32_type])))], ["GetTemp", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))], ["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))]]);
}

export class Fvcom_Element extends Record {
    constructor(GetSiglev, GetSiglay, GetBathymetry, GetUv, GetSpeed) {
        super();
        this.GetSiglev = GetSiglev;
        this.GetSiglay = GetSiglay;
        this.GetBathymetry = GetBathymetry;
        this.GetUv = GetUv;
        this.GetSpeed = GetSpeed;
    }
}

export function Fvcom_Element_$reflection() {
    return record_type("Sorcerer.Api.Fvcom.Element", [], Fvcom_Element, () => [["GetSiglev", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetSiglay", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetBathymetry", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [float32_type])))], ["GetUv", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))]))))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))]]);
}

export class Fvcom_Batch extends Record {
    constructor(GetNodeDepths, GetElementDepths, GetSpeed, GetTemp, GetSalinity) {
        super();
        this.GetNodeDepths = GetNodeDepths;
        this.GetElementDepths = GetElementDepths;
        this.GetSpeed = GetSpeed;
        this.GetTemp = GetTemp;
        this.GetSalinity = GetSalinity;
    }
}

export function Fvcom_Batch_$reflection() {
    return record_type("Sorcerer.Api.Fvcom.Batch", [], Fvcom_Batch, () => [["GetNodeDepths", lambda_type(class_type("System.Guid"), lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))])))], ["GetElementDepths", lambda_type(class_type("System.Guid"), lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))])))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))]))))], ["GetTemp", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))]))))], ["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))]))))]]);
}

export class Fvcom_TimeSeries extends Record {
    constructor(GetSpeed, GetUv, GetTemp, GetSalinity, GetZeta) {
        super();
        this.GetSpeed = GetSpeed;
        this.GetUv = GetUv;
        this.GetTemp = GetTemp;
        this.GetSalinity = GetSalinity;
        this.GetZeta = GetZeta;
    }
}

export function Fvcom_TimeSeries_$reflection() {
    return record_type("Sorcerer.Api.Fvcom.TimeSeries", [], Fvcom_TimeSeries, () => [["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetUv", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))])))))], ["GetTemp", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetZeta", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))]]);
}

export const Drifters_routeBuilder = (typeName) => ((methodName) => routeBuilder("drifters", typeName, methodName));

export class Drifters_Archive extends Record {
    constructor(GetNumFrames, GetSimType) {
        super();
        this.GetNumFrames = GetNumFrames;
        this.GetSimType = GetSimType;
    }
}

export function Drifters_Archive_$reflection() {
    return record_type("Sorcerer.Api.Drifters.Archive", [], Drifters_Archive, () => [["GetNumFrames", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["GetSimType", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [DriftersVariant_$reflection()]))]]);
}

export class Drifters_Particles extends Record {
    constructor(GetFrame, GetReleaseSites) {
        super();
        this.GetFrame = GetFrame;
        this.GetReleaseSites = GetReleaseSites;
    }
}

export function Drifters_Particles_$reflection() {
    return record_type("Sorcerer.Api.Drifters.Particles", [], Drifters_Particles, () => [["GetFrame", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(ApiParticle_$reflection())])))], ["GetReleaseSites", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type, float32_type))]))]]);
}

export class Drifters_Network extends Record {
    constructor(GetReleaseSites, GetConnectionMatrix) {
        super();
        this.GetReleaseSites = GetReleaseSites;
        this.GetConnectionMatrix = GetConnectionMatrix;
    }
}

export function Drifters_Network_$reflection() {
    return record_type("Sorcerer.Api.Drifters.Network", [], Drifters_Network, () => [["GetReleaseSites", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(anonRecord_type(["coord", tuple_type(float64_type, float64_type)], ["radius", float64_type]))]))], ["GetConnectionMatrix", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float64_type))])))))))]]);
}

export class Drifters_Sedimentation extends Record {
    constructor(GetField, GetContour, GetSeries) {
        super();
        this.GetField = GetField;
        this.GetContour = GetContour;
        this.GetSeries = GetSeries;
    }
}

export function Drifters_Sedimentation_$reflection() {
    return record_type("Sorcerer.Api.Drifters.Sedimentation", [], Drifters_Sedimentation, () => [["GetField", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(SedimentKind_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetContour", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(SedimentKind_$reflection(), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(tuple_type(float64_type, float64_type)))]))))))], ["GetSeries", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type), lambda_type(FieldKind_$reflection(), lambda_type(SedimentKind_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))]]);
}

export class Drifters_Field2D extends Record {
    constructor(GetWaterContactField, GetWaterContactSeries, GetField, GetSeries) {
        super();
        this.GetWaterContactField = GetWaterContactField;
        this.GetWaterContactSeries = GetWaterContactSeries;
        this.GetField = GetField;
        this.GetSeries = GetSeries;
    }
}

export function Drifters_Field2D_$reflection() {
    return record_type("Sorcerer.Api.Drifters.Field2D", [], Drifters_Field2D, () => [["GetWaterContactField", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["GetWaterContactSeries", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type), lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["GetField", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["GetSeries", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type), lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))]]);
}

export class Drifters_Field3D extends Record {
    constructor(GetField, GetSeries) {
        super();
        this.GetField = GetField;
        this.GetSeries = GetSeries;
    }
}

export function Drifters_Field3D_$reflection() {
    return record_type("Sorcerer.Api.Drifters.Field3D", [], Drifters_Field3D, () => [["GetField", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), lambda_type(list_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["GetSeries", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type), lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), lambda_type(list_type(int32_type), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))))]]);
}

export class Drifters_FieldMetaData extends Record {
    constructor(GetAvailableFields, GetAvailableLayers, GetAvailableGroups, GetAvailableParticles) {
        super();
        this.GetAvailableFields = GetAvailableFields;
        this.GetAvailableLayers = GetAvailableLayers;
        this.GetAvailableGroups = GetAvailableGroups;
        this.GetAvailableParticles = GetAvailableParticles;
    }
}

export function Drifters_FieldMetaData_$reflection() {
    return record_type("Sorcerer.Api.Drifters.FieldMetaData", [], Drifters_FieldMetaData, () => [["GetAvailableFields", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(FieldKind_$reflection())]))], ["GetAvailableLayers", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(LayerType_$reflection())]))], ["GetAvailableGroups", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(GroupType_$reflection())]))], ["GetAvailableParticles", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(ParticleType_$reflection())]))]]);
}

export const Atmo_routeBuilder = (typeName) => ((methodName) => routeBuilder("atmo", typeName, methodName));

export class Atmo_Wind extends Record {
    constructor(WindTile, GetBarbSigns) {
        super();
        this.WindTile = WindTile;
        this.GetBarbSigns = GetBarbSigns;
    }
}

export function Atmo_Wind_$reflection() {
    return record_type("Sorcerer.Api.Atmo.Wind", [], Atmo_Wind, () => [["WindTile", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, lambda_type(int32_type, lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(option_type(V2$1_$reflection(float32_type)))])))))))], ["GetBarbSigns", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(Arrow$1_$reflection(float64_type))]))]]);
}

export const Proximity_routeBuilder = (typeName) => ((methodName) => routeBuilder("prox", typeName, methodName));

export class Proximity_Prox extends Record {
    constructor(GetNearestElements, GetNearestNodes) {
        super();
        this.GetNearestElements = GetNearestElements;
        this.GetNearestNodes = GetNearestNodes;
    }
}

export function Proximity_Prox_$reflection() {
    return record_type("Sorcerer.Api.Proximity.Prox", [], Proximity_Prox, () => [["GetNearestElements", lambda_type(tuple_type(class_type("System.Guid"), array_type(tuple_type(float64_type, float64_type))), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(option_type(int32_type))]))], ["GetNearestNodes", lambda_type(tuple_type(class_type("System.Guid"), array_type(tuple_type(float64_type, float64_type))), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(option_type(int32_type))]))]]);
}

export const Stats_routeBuilder = (typeName) => ((methodName) => routeBuilder("stats", typeName, methodName));

export class Stats_FvStatsInfo extends Record {
    constructor(GetAvailableStats, GetSectors, GetNumLayers) {
        super();
        this.GetAvailableStats = GetAvailableStats;
        this.GetSectors = GetSectors;
        this.GetNumLayers = GetNumLayers;
    }
}

export function Stats_FvStatsInfo_$reflection() {
    return record_type("Sorcerer.Api.Stats.FvStatsInfo", [], Stats_FvStatsInfo, () => [["GetAvailableStats", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(StatProp_$reflection())]))], ["GetSectors", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))], ["GetNumLayers", lambda_type(class_type("System.Guid"), lambda_type(StatProp_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type])))]]);
}

export class Stats_FvStatsByLayer extends Record {
    constructor(GetSalinity, GetTemperature, GetUV, GetSpeed) {
        super();
        this.GetSalinity = GetSalinity;
        this.GetTemperature = GetTemperature;
        this.GetUV = GetUV;
        this.GetSpeed = GetSpeed;
    }
}

export function Stats_FvStatsByLayer_$reflection() {
    return record_type("Sorcerer.Api.Stats.FvStatsByLayer", [], Stats_FvStatsByLayer, () => [["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetTemperature", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetUV", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))])))))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))]]);
}

export class Stats_FvStatsByIndex extends Record {
    constructor(GetSalinity, GetTemperature, GetUV, GetSpeed, GetWaterTransport) {
        super();
        this.GetSalinity = GetSalinity;
        this.GetTemperature = GetTemperature;
        this.GetUV = GetUV;
        this.GetSpeed = GetSpeed;
        this.GetWaterTransport = GetWaterTransport;
    }
}

export function Stats_FvStatsByIndex_$reflection() {
    return record_type("Sorcerer.Api.Stats.FvStatsByIndex", [], Stats_FvStatsByIndex, () => [["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetTemperature", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetUV", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))])))))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetWaterTransport", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))]))))]]);
}

export class Stats_FvStatsSeries extends Record {
    constructor(GetSalinity, GetTemperature, GetSpeed) {
        super();
        this.GetSalinity = GetSalinity;
        this.GetTemperature = GetTemperature;
        this.GetSpeed = GetSpeed;
    }
}

export function Stats_FvStatsSeries_$reflection() {
    return record_type("Sorcerer.Api.Stats.FvStatsSeries", [], Stats_FvStatsSeries, () => [["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetTemperature", lambda_type(class_type("System.Guid"), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))]]);
}

export const Crop_routeBuilder = (typeName) => ((methodName) => routeBuilder("crop", typeName, methodName));

export class Crop_Fvcom extends Record {
    constructor(CropTemp, CropSalinity, CropSpeed, CropUV) {
        super();
        this.CropTemp = CropTemp;
        this.CropSalinity = CropSalinity;
        this.CropSpeed = CropSpeed;
        this.CropUV = CropUV;
    }
}

export function Crop_Fvcom_$reflection() {
    return record_type("Sorcerer.Api.Crop.Fvcom", [], Crop_Fvcom, () => [["CropTemp", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["CropSalinity", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["CropSpeed", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["CropUV", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))])))))))]]);
}

export class Crop_Stats extends Record {
    constructor(CropTemp, CropSalinity, CropSpeed, CropUV) {
        super();
        this.CropTemp = CropTemp;
        this.CropSalinity = CropSalinity;
        this.CropSpeed = CropSpeed;
        this.CropUV = CropUV;
    }
}

export function Crop_Stats_$reflection() {
    return record_type("Sorcerer.Api.Crop.Stats", [], Crop_Stats, () => [["CropTemp", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["CropSalinity", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["CropSpeed", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["CropUV", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))]))))))))]]);
}

export const Ocean_routeBuilder = (typeName) => ((methodName) => routeBuilder("ocean", typeName, methodName));

export class Ocean_PointRequest extends Record {
    constructor(aid, coord, time) {
        super();
        this.aid = aid;
        this.coord = coord;
        this.time = time;
    }
}

export function Ocean_PointRequest_$reflection() {
    return record_type("Sorcerer.Api.Ocean.PointRequest", [], Ocean_PointRequest, () => [["aid", class_type("System.Guid")], ["coord", tuple_type(float64_type, float64_type)], ["time", class_type("System.DateTime")]]);
}

export class Ocean_TimeseriesRequest extends Record {
    constructor(aid, coord, depth, time, days) {
        super();
        this.aid = aid;
        this.coord = coord;
        this.depth = depth;
        this.time = time;
        this.days = (days | 0);
    }
}

export function Ocean_TimeseriesRequest_$reflection() {
    return record_type("Sorcerer.Api.Ocean.TimeseriesRequest", [], Ocean_TimeseriesRequest, () => [["aid", class_type("System.Guid")], ["coord", tuple_type(float64_type, float64_type)], ["depth", float64_type], ["time", class_type("System.DateTime")], ["days", int32_type]]);
}

export class Ocean_Property extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Temperature", "Salinity", "Speed", "UV", "UVW", "Void"];
    }
}

export function Ocean_Property_$reflection() {
    return union_type("Sorcerer.Api.Ocean.Property", [], Ocean_Property, () => [[["Item", array_type(float32_type)]], [["Item", array_type(float32_type)]], [["Item", array_type(float32_type)]], [["Item", array_type(tuple_type(float32_type, float32_type))]], [["Item", array_type(tuple_type(float32_type, float32_type, float32_type))]], [["Item", string_type]]]);
}

export class Ocean_Response extends Record {
    constructor(name, coord, time, timeStep, depth, data) {
        super();
        this.name = name;
        this.coord = coord;
        this.time = time;
        this.timeStep = timeStep;
        this.depth = depth;
        this.data = data;
    }
}

export function Ocean_Response_$reflection() {
    return record_type("Sorcerer.Api.Ocean.Response", [], Ocean_Response, () => [["name", string_type], ["coord", tuple_type(float64_type, float64_type)], ["time", class_type("System.DateTime")], ["timeStep", option_type(int32_type)], ["depth", array_type(float32_type)], ["data", Ocean_Property_$reflection()]]);
}

export class Ocean_Pointwise extends Record {
    constructor(GetTemp, GetSalinity, GetSpeed, GetUV, GetUVW) {
        super();
        this.GetTemp = GetTemp;
        this.GetSalinity = GetSalinity;
        this.GetSpeed = GetSpeed;
        this.GetUV = GetUV;
        this.GetUVW = GetUVW;
    }
}

export function Ocean_Pointwise_$reflection() {
    return record_type("Sorcerer.Api.Ocean.Pointwise", [], Ocean_Pointwise, () => [["GetTemp", lambda_type(Ocean_PointRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))], ["GetSalinity", lambda_type(Ocean_PointRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))], ["GetSpeed", lambda_type(Ocean_PointRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))], ["GetUV", lambda_type(Ocean_PointRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))], ["GetUVW", lambda_type(Ocean_PointRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))]]);
}

export class Ocean_Timeseries extends Record {
    constructor(GetTemp, GetSalinity, GetSpeed, GetUV, GetUVW) {
        super();
        this.GetTemp = GetTemp;
        this.GetSalinity = GetSalinity;
        this.GetSpeed = GetSpeed;
        this.GetUV = GetUV;
        this.GetUVW = GetUVW;
    }
}

export function Ocean_Timeseries_$reflection() {
    return record_type("Sorcerer.Api.Ocean.Timeseries", [], Ocean_Timeseries, () => [["GetTemp", lambda_type(Ocean_TimeseriesRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))], ["GetSalinity", lambda_type(Ocean_TimeseriesRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))], ["GetSpeed", lambda_type(Ocean_TimeseriesRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))], ["GetUV", lambda_type(Ocean_TimeseriesRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))], ["GetUVW", lambda_type(Ocean_TimeseriesRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(Ocean_Response_$reflection())]))]]);
}

