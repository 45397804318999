import { Record, Union } from "../../fable_modules/fable-library-js.4.21.0/Types.js";
import { bool_type, float64_type, enum_type, class_type, array_type, record_type, string_type, int32_type, union_type } from "../../fable_modules/fable-library-js.4.21.0/Reflection.js";

export class SlurmJobType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DriftersJob", "PlumeJob", "UnknownJob"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "Plume" : ((x.tag === 2) ? "Unknown" : "Drifters");
    }
}

export function SlurmJobType_$reflection() {
    return union_type("Hipster.Job.SlurmJobType", [], SlurmJobType, () => [[], [], []]);
}

export function SlurmJobType_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "drifters":
            return new SlurmJobType(0, []);
        case "plume":
            return new SlurmJobType(1, []);
        default:
            return new SlurmJobType(2, []);
    }
}

export class SlurmMessageType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Status", "Progress", "Message"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "progress" : ((x.tag === 2) ? "message" : "status");
    }
}

export function SlurmMessageType_$reflection() {
    return union_type("Hipster.Job.SlurmMessageType", [], SlurmMessageType, () => [[], [], []]);
}

export function SlurmMessageType_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "status":
            return new SlurmMessageType(0, []);
        case "progress":
            return new SlurmMessageType(1, []);
        default:
            return new SlurmMessageType(2, []);
    }
}

export class SlurmJobStatusMsg extends Record {
    constructor(jobId, agentId, jobType, messageType, content) {
        super();
        this.jobId = (jobId | 0);
        this.agentId = agentId;
        this.jobType = jobType;
        this.messageType = messageType;
        this.content = content;
    }
}

export function SlurmJobStatusMsg_$reflection() {
    return record_type("Hipster.Job.SlurmJobStatusMsg", [], SlurmJobStatusMsg, () => [["jobId", int32_type], ["agentId", string_type], ["jobType", string_type], ["messageType", string_type], ["content", string_type]]);
}

export class JobProgress extends Record {
    constructor(progress, eta) {
        super();
        this.progress = (progress | 0);
        this.eta = (eta | 0);
    }
}

export function JobProgress_$reflection() {
    return record_type("Hipster.Job.JobProgress", [], JobProgress, () => [["progress", int32_type], ["eta", int32_type]]);
}

export class JobInfo extends Record {
    constructor(owner, groups, jobId, archiveId, refId, status, name, startTime, simDays, submitTime, submitQueue) {
        super();
        this.owner = owner;
        this.groups = groups;
        this.jobId = (jobId | 0);
        this.archiveId = archiveId;
        this.refId = refId;
        this.status = (status | 0);
        this.name = name;
        this.startTime = startTime;
        this.simDays = simDays;
        this.submitTime = submitTime;
        this.submitQueue = submitQueue;
    }
}

export function JobInfo_$reflection() {
    return record_type("Hipster.Job.JobInfo", [], JobInfo, () => [["owner", string_type], ["groups", array_type(string_type)], ["jobId", int32_type], ["archiveId", class_type("System.Guid")], ["refId", class_type("System.Guid")], ["status", enum_type("Hipster.Job.JobStatus", int32_type, [["New", 0], ["Waiting", 1], ["Running", 2], ["Completed", 3], ["Failed", 4], ["Unknown", 5]])], ["name", string_type], ["startTime", class_type("System.DateTime")], ["simDays", float64_type], ["submitTime", class_type("System.DateTime")], ["submitQueue", string_type]]);
}

export class JobMessage extends Record {
    constructor(aid, job, status, name) {
        super();
        this.aid = aid;
        this.job = (job | 0);
        this.status = (status | 0);
        this.name = name;
    }
}

export function JobMessage_$reflection() {
    return record_type("Hipster.Job.JobMessage", [], JobMessage, () => [["aid", class_type("System.Guid")], ["job", int32_type], ["status", enum_type("Hipster.Job.JobStatus", int32_type, [["New", 0], ["Waiting", 1], ["Running", 2], ["Completed", 3], ["Failed", 4], ["Unknown", 5]])], ["name", string_type]]);
}

export class ProgressMessage extends Record {
    constructor(aid, job, name, progress, eta) {
        super();
        this.aid = aid;
        this.job = (job | 0);
        this.name = name;
        this.progress = (progress | 0);
        this.eta = eta;
    }
}

export function ProgressMessage_$reflection() {
    return record_type("Hipster.Job.ProgressMessage", [], ProgressMessage, () => [["aid", class_type("System.Guid")], ["job", int32_type], ["name", string_type], ["progress", int32_type], ["eta", float64_type]]);
}

export class DriftersPolicy extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SubmitTransport", "SubmitSedimentation"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? (`run:sedimentation;${x.fields[0]}`) : (`run:transport;${x.fields[0]}`);
    }
}

export function DriftersPolicy_$reflection() {
    return union_type("Hipster.Job.DriftersPolicy", [], DriftersPolicy, () => [[["Item", bool_type]], [["Item", bool_type]]]);
}

export function DriftersPolicy__IsAllowed(x) {
    if (x.tag === 0) {
        return x.fields[0];
    }
    else {
        return x.fields[0];
    }
}

